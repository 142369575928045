<template>

    <router-view></router-view>

</template>

<script>

    export default {
        name: 'admin',
        data: () => ({}),
        components: {}
    }

</script>

<style>

</style>